* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.content {
  padding: 40px 200px;
}

.contact-container {
  padding: 0px 30px;
}

.contact-card {
  padding: 20px;
  background-color: #e3f5ff;
}

.input-label {
  font-weight: bold;
}

.btn-submit {
  background-color: #06aeef;
  color: white;
}

.btn-submit:hover {
  background-color: #06aeef;
  color: white;
}

.input {
  padding: 10px;
  outline: none;
  border-radius: 5px;
  border: 2px solid rgb(181, 181, 181);
  width: 100%;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.footer-bottom-section a {
  color: white;
}

.header {
  display: flex;
  padding: 20px 200px;
}

.button {
  border: none;
  outline: none;
  padding: 13px 30px;
  background-color: #0192e3;
  color: white;
  border-radius: 30px;
  font-size: 20px;
}

.menu {
  flex: 1;
  display: flex;
}

.hero-section {
  position: relative;
  background: linear-gradient(to right bottom, #ebf9ff, #ffedd0, #ffd4d5);
  height: 650px;
}

.hero-section-content {
  width: 400px;
}

.banner {
  display: flex;
  padding: 50px 200px;
}

.feature {
  display: flex;
  padding: 30px 200px;
}

a {
  text-decoration: none !important;
}

.footer-menu a {
  color: white;
}

.header a {
  color: #212529;
}

.footer-container {
  margin-top: 100px;
  background-color: black;
  padding: 30px;
  color: white;
}

.footer-middle-section {
  display: flex;
  padding: 40px 200px;
}

.footer-bottom-section {
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 0px 200px;
}

.footer-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  padding: 0px 200px;
}

.hero-section-image-container {
  flex: 1;
  align-content: center;
}

.hero-section-image {
  height: 250px;
}

.hero-section-tagline {
  font-weight: 600;
  font-size: 30px;
}

.section-subheading {
  width: 450px;
  padding-top: 15px;
}

.section-image {
  height: 350px;
  width: 450px;
}

.why-us-container {
  margin: 0px 200px;
  background-color: #e3f5ff;
  padding: 50px;
}

.about-us-container {
  display: flex;
  margin: 0px 200px;
}

.about-us-image {
  height: 350px;
  width: 450px;
}

.footer-menu {
  display: flex;
  gap: 20px;
}

.responsive-menu-bar {
  display: none;
}

.menu-item {
  padding: 10px;
}

.responsive-menu {
  background-color: #0092e3;
  padding: 10px;
  color: white;
  width: 100%;
  position: absolute;
  top: 90px;
  left: 0px;
}

.error{
  border: 2px solid red;
}

@media (max-width: 480px) {
  .contact-container {
    padding: 0px 0px;
    padding-bottom: 30px;
  }

  .content {
    padding: 52px 20px;
  }

  .header a {
    color: #fff;
  }

  .menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #0092e3;
    top: 85px;
    left: 10px;
    right: 10px;
  }

  .hide {
    display: none;
  }

  .footer-middle-section {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 0px 0px;
  }

  .footer-bottom-section {
    flex-direction: column;
    display: flex;
    padding: 0px 0px;
  }

  .menu-item {
    color: white;
  }

  .footer-header {
    gap: 18px;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    padding: 0px 0px;
  }

  .footer-menu {
    flex-direction: column;
  }

  .about-us-image {
    margin-top: 50px;
    height: auto;
    width: 350px;
  }

  .hero-section-content {
    width: auto;
  }

  .about-us-container {
    flex-direction: column;
    margin: 0px 0px;
  }

  .why-us-container {
    margin: 0px 0px;
    background-color: #e3f5ff;
    padding: 50px;
  }

  .header {
    position: absolute;
    width: 100%;
    align-items: center;
    padding: 20px 20px;
  }

  .section-image {
    height: 200px;
    width: 250px;
  }

  .hero-section-tagline {
    font-weight: 600;
    font-size: 20px;
  }

  .hero-section-image {
    margin-top: 50px;
    height: 150px;
  }

  .feature {
    text-align: center;
    padding: 30px 20px;
    flex-direction: column;
  }

  .feature-reverse {
    text-align: center;
    flex-direction: column-reverse;
  }

  .banner {
    text-align: center;
    flex-direction: column;
    padding: 120px 34px;
  }

  .responsive-menu-bar {
    display: block;
  }

  .section-subheading {
    width: 250px;
    padding-top: 15px;
  }
}
